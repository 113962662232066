import React from 'react';
import './project.css';
import { FaGithub, FaDownload, FaExternalLinkAlt } from 'react-icons/fa';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default class Project extends React.Component {
  render() {
    const projectInfo = this.props.projectInfo;
    return (
      <div className="card" style={{height: "100%", display: "flex", flexDirection: "column"}}>
        <header className="card-header">
          <p className="card-header-title is-size-5">
            {projectInfo.frontmatter.title}
          </p>
        </header>
        <div className="card-image">
          <a href={projectInfo.frontmatter.live_path || projectInfo.fields.slug || projectInfo.frontmatter.github}>
            <Img fluid={projectInfo.frontmatter.image.childImageSharp.fluid}/>
          </a>
        </div>
        <div className="card-content" style={{flex: "auto"}}>
          <div className="content">
            <p style={{ marginTop: '1rem' }}>
              {projectInfo.excerpt}
            </p>
          </div>
        </div>
        <footer className="card-footer">
          {!projectInfo.frontmatter.no_overview &&
          <Link className="card-footer-item" to={projectInfo.fields.slug}>More</Link>
          }

          {projectInfo.frontmatter.download_path &&
          <a className="card-footer-item" href={projectInfo.frontmatter.download_path}>
            Download <span className="icon"><FaDownload/></span>
          </a>
          }

          {projectInfo.frontmatter.live_path &&
          <a className="card-footer-item" href={projectInfo.frontmatter.live_path}>
            Live <span className="icon"><FaExternalLinkAlt/></span>
          </a>
          }

          {projectInfo.frontmatter.component &&
          <Link className="card-footer-item" to={projectInfo.fields.slug + "live"}>
            Live <span className="icon"><FaExternalLinkAlt/></span>
          </Link>
          }

          {projectInfo.frontmatter.github &&
          <a className="card-footer-item is-pulled-right" href={projectInfo.frontmatter.github}>
            Code <span className="icon"><FaGithub/></span>
          </a>
          }
        </footer>
      </div>
    );
  }
}

export const query = graphql`
  fragment ProjectInformation on MarkdownRemark {
    frontmatter {
      title
      github
      no_overview
      live_path
      download_path
      image {
        childImageSharp {
          fluid(maxWidth: 320, maxHeight: 320, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    fields {
      slug
    }
    excerpt
  }
`
/*
      component {
        id
      };
 */