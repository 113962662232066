import React from 'react';
import { graphql } from 'gatsby';
import Project from '../components/project';
import PageLayout from '../layouts/pageLayout';
import { Helmet } from 'react-helmet';

export default ({data}) => {
  const projects = data.allMarkdownRemark.edges.map(({ node }) => {
    return (
      <div key={node.id}  className="column is-half is-one-quarter-widescreen">
        <Project projectInfo={node}/>
      </div>
      );
  });

  return (
    <PageLayout>
      <Helmet>
        <title>Iteem - projects</title>
      </Helmet>
      <h1 className="title is-1 has-text-centered">Projects</h1>
      <div className="columns is-multiline">
        {projects}
      </div>
    </PageLayout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: {fields: frontmatter___title}) {
      edges {
        node {
          id
          ...ProjectInformation
        }
      }
    }
  }
`;